<template>
  <div >
    <div class="text-lg-h4 text-h6">{{liveSource.name}}</div>
    <v-layout class="grey darken-2" justify-center :fill-height="true">
      <v-flex lg6>
        <div>
          <div
            class="fb-video"
            :data-href="liveSource.streaming_url"
            data-autoplay="true"
            data-width="auto"
            data-show-text="true"
            data-lazy="true"
            data-allowfullscreen="true"
          ></div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: 'LivePanel',
  props:{
    liveSource:{
      type:Object
    }
  }
}
</script>

<style>
</style>